import { FC, useEffect } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import TextField from '@mui/material/TextField';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import cn from 'classnames';
import { addDays, addMinutes, format } from 'date-fns';
import dayjs from 'dayjs';
import moment from 'moment';

import { FE_DATE_FORMAT, HOURS_TIME } from '@/constants/global';
import { Route } from '@/constants/routes';
import { useDateRent } from '@/context/useDateRent';
import { AutocompleteAddressRentDate } from '@/features/AutocompleteAddressRentDate';
import { Car, LocaleType, PlaceType } from '@/types';
import { useValidation } from '@/utils/useValidation';

const SearchArrows = require('assets/icons/searchArrows.svg');

type Props = {
  locale: LocaleType;
  car?: Car;
  errors: { id: string; message: string }[];
  setErrors: (isError: boolean, errors?: any[]) => void;
};

export const MainDateAndAddress: FC<Props> = ({ locale, car = null, errors, setErrors }) => {
  const { getRentData, setRentData } = useDateRent();
  const { validateDateAndAddress } = useValidation();
  const router = useRouter();

  const rentData = getRentData();
  const { startDate, endDate, startTime, endTime, addressToDeliverGeo, addressToPickupGeo } = rentData;

  const { t } = useTranslation();

  const startDateTime = `${startDate} ${startTime}`;
  const endDateTime = `${endDate} ${endTime}`;

  useEffect(() => {
    const momentStartDate = moment(startDate);
    const momentEndDate = moment(endDate);

    if (momentStartDate.isBefore(moment().add(1, 'day'))) {
      setRentData({
        startDate: format(addMinutes(addDays(new Date(), 2), 60), FE_DATE_FORMAT),
        startTime: format(addMinutes(addDays(new Date(), 2), 60), HOURS_TIME),
      });
    }

    if (momentEndDate.isBefore(moment().add(1, 'day'))) {
      setRentData({
        endDate: format(addMinutes(addDays(new Date(), 9), 60), FE_DATE_FORMAT),
        endTime: format(addMinutes(addDays(new Date(), 9), 60), HOURS_TIME),
      });
    }
  }, [startDate, endDate]);

  const saveDateAndAddress = () => {
    const errorsDateAndAddress = validateDateAndAddress({
      startDate,
      endDate,
      startTime,
      endTime,
      addressToDeliverGeo,
      addressToPickupGeo,
    });

    setRentData({
      car,
    });

    if (errorsDateAndAddress.length) {
      setErrors(true, errorsDateAndAddress);
      return;
    } else {
      setErrors(false, []);
    }

    router.push(addressToDeliverGeo?.info?.description ? Route.BookingChooseCar : Route.BookingSearchCar);
  };

  const onAddressToDeliverChange = (newAddress: PlaceType | null) => {
    setRentData({
      addressToDeliverGeo: newAddress,
      addressToPickupGeo: newAddress,
    });
  };

  const startAddressError = errors?.length
    ? errors.find((item) => item.id === 'addressToDeliverGeo')?.message || null
    : null;

  const startDateError = errors?.length
    ? errors.find((item) => item.id === 'startDate' || item.id === 'startDateRange')?.message || null
    : null;

  const endDateError =     errors?.length
    ? errors.find((item) => item.id === 'endDate' || item.id === 'endDateRange')?.message || null
    : null;

  return (
    <div
      className={cn('ml-0 lg:ml-40 lg:w-5/6 2xl:w-4/5 flex flex-col md:flex-row md:items-center', {
        'gap-3 xl:gap-7': !startDateError && !endDateError,
        'gap-7 xl:gap-5': startDateError || endDateError,
      })}>
      <div
        className={cn('bg-white shadow-md w-full rounded-xl md:w-56 relative')}>
        <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDayjs}>
          <MobileDateTimePicker
            ampm={false}
            ampmInClock={false}
            className="myDatePicker"
            inputFormat="DD/MM/YYYY  |  hh:mm A"
            minDate={dayjs().add(1, 'day').add(60, 'minutes')}
            onChange={(newValue) => {
              setRentData({
                startDate: newValue!.format('YYYY-MM-DD'),
                startTime: newValue!.format('HH:mm'),
              });
            }}
            renderInput={(props) => <TextField {...props}       
              error={!!startDateError}
              sx={{
              "& .MuiOutlinedInput-root": {
                "&.Mui-error fieldset": {
                  borderColor: "#EE1515",
                },
              },
            }}   
            />}
            value={dayjs(startDateTime)}
          />
        </LocalizationProvider>
        {!!startDateError && (
          <div className="whitespace-nowrap md:whitespace-normal absolute  top-14 md:top-16 left-2 text-[#EE1515] text-sm">{startDateError}</div>
        )}
      </div>
      <div
        className={cn('bg-white shadow-md w-full rounded-xl md:w-56 relative')}>
        <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDayjs}>
          <MobileDateTimePicker
            ampm={false}
            ampmInClock={false}
            className="myDatePicker"
            inputFormat="DD/MM/YYYY  |  hh:mm A"
            minDate={dayjs().add(2, 'day')}
            onChange={(newValue) => {
              setRentData({
                endDate: newValue!.format('YYYY-MM-DD'),
                endTime: newValue!.format('HH:mm'),
              });
            }}
            renderInput={(props) => <TextField {...props}
              error={!!endDateError}
              sx={{
              "& .MuiOutlinedInput-root": {
                "&.Mui-error fieldset": {
                  borderColor: "#EE1515",
                },
              },
            }} 
            />}
            value={dayjs(endDateTime)}
          />
        </LocalizationProvider>
        {!!endDateError && (
          <div className="whitespace-nowrap md:whitespace-normal absolute  top-14 md:top-16 left-2 text-[#EE1515] text-sm">{endDateError}</div>
        )}
      </div>
      <div className="w-full bg-white shadow-md rounded-xl tablet:w-1/3 xl:w-1/2">
        <AutocompleteAddressRentDate
          defaultValue={addressToDeliverGeo}
          errorMessage={startAddressError}
          toggleState={(addressToDeliverGeoNew) => {
            onAddressToDeliverChange(addressToDeliverGeoNew);
          }}
        />
      </div>
      <div>
      <button
        // eslint-disable-next-line max-len
        className="self-end transition bg-white rounded-full shadow-md flex-center h-14 w-14 md:self-auto hover:bg-blue-50"
        onClick={saveDateAndAddress}>
        <Image alt={t('rentCar.search')} className="h-4" src={SearchArrows} />
      </button>
      </div>
    </div>
  );
};
