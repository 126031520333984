/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';

import { GoogleMapsContext, GoogleMapsContextType } from '@/context/googleContext';
import { PlaceInfo, PlaceType } from '@/types';

type Props = {
  defaultValue?: PlaceType | null;
  toggleState: (address: PlaceType | null) => void;
  className?: string;
  errorMessage?: string | null;
};

export const AutocompleteAddressRentDate: FC<Props> = ({ defaultValue, errorMessage, toggleState, className }) => {
  const { t } = useTranslation();

  const [value, setValue] = useState<PlaceInfo | null>(defaultValue ? defaultValue?.info : null);
  const [inputValue, setInputValue] = useState<string>('');
  const [options, setOptions] = useState<readonly PlaceInfo[]>([]);

  const { service: googleMapApi } = useContext<GoogleMapsContextType>(GoogleMapsContext);

  const autocompleteService = useRef<google.maps.places.AutocompleteService | null>(
    googleMapApi ? new googleMapApi.places.AutocompleteService() : null
  );

  const geoCoderService = useRef<google.maps.Geocoder | null>(googleMapApi ? new googleMapApi.Geocoder() : null);

  useEffect(() => {
    setInputValue(defaultValue?.info ? defaultValue?.info.description : '');
    setValue(defaultValue ? defaultValue!.info : null);
  }, [defaultValue]);

  useEffect(() => {
    if (googleMapApi && !autocompleteService.current) {
      autocompleteService.current = new googleMapApi.places.AutocompleteService();
      geoCoderService.current = new googleMapApi.Geocoder();
    }
  }, [googleMapApi]);

  const fetch = useMemo(
    () =>
      throttle((request: { input: string }, callback: (results: any /* readonly PlaceType[] | null */) => void) => {
        if (autocompleteService.current) {
          autocompleteService.current.getPlacePredictions(request, callback);
        }
      }, 100),
    []
  );

  const fetchCoordinates = async ({ placeId }: { placeId: string }) => {
    const coordinates = await geoCoderService.current?.geocode({ placeId });

    return {
      latitude: coordinates?.results[0].geometry.location.lat(),
      longitude: coordinates?.results[0].geometry.location.lng(),
    };
  };

  const handleSetValue = async (_: any, newValue: PlaceInfo | null) => {
    setValue(newValue);
    if (newValue) {
      const coordinates = await fetchCoordinates({ placeId: newValue.place_id });

      toggleState({
        info: newValue,
        ...coordinates,
        latitude: coordinates.latitude || null,
        longitude: coordinates.longitude || null,
      });
    } else {
      toggleState({
        info: newValue,
        latitude: null,
        longitude: null,
      });
    }
  };

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      setOptions(results);

      if (active) {
        let newOptions: readonly PlaceInfo[] = [];
        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      autoComplete
      className={`searchAddressRent ${className}`}
      filterOptions={(x) => x}
      filterSelectedOptions
      fullWidth
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      id="google-map-demo-start-date"
      includeInputInList
      // @ts-ignore
      onChange={handleSetValue}
      // @ts-ignore
      onInputChange={(event: any, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={options || []}
      renderInput={(params) => (
        <div className={`rounded-xl relative`}>
          <TextField
            {...params} error={!!errorMessage} fullWidth 
            placeholder={`${t('mainPage.pickUp')}`}
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.Mui-error fieldset": {
                  borderColor: "#EE1515",
                },
              },
            }} 
            />
          {!!errorMessage && (
            <div className="whitespace-nowrap md:whitespace-normal absolute  top-14 md:top-16 left-1 text-[#EE1515] text-sm ">{errorMessage}</div>
          )}
        </div>
      )}
      renderOption={(localProps, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length])
        );

        return (
          <li {...localProps}>
            <Grid alignItems="center" container>
              <Grid item />
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}>
                    {part.text}
                  </span>
                ))}
                <Typography color="text.secondary" variant="body2">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
      value={value}
    />
  );
};
