import { useState } from 'react';

export const useErrors = () => {
  const [isError, setIsError] = useState<boolean>(false);
  const [errors, setErrors] = useState<any[]>([]);

  const setErrorsOuter = (isHasOuterError: boolean, localErrors?: any[]) => {
    setIsError(isHasOuterError);

    if (localErrors) {
      setErrors(localErrors);
    }
  };

  return { isError, errors, setErrors: setErrorsOuter };
};
